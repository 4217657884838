import React from 'react'
import { Container, Image,Box, VStack, HStack } from '@chakra-ui/react'
import back_icon from '../../assets/back-icon.png'
const ViewPlans = (props) => {
  return (
    <Container bg={'#FFFFFF'}
    position={'absolute'}
    h={'100vh'} maxW={'100vw'}
    top={0}
    left={0}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
>
    <VStack h={'100%'}  w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
<Box h={'8%'}  w={'100%'} justifyContent={'flex-start'} padding={2}>
        <Image h={10} src={back_icon} alt=''  alignSelf={'left'}
     _hover={{ cursor: "pointer" }}
    onClick={()=> props.setviewplan(!props.viewplan)}/>
</Box>
<Box h={'92%'} w={'100%'} display={'flex'} justifyContent={'center'} alignItems={'flex-start'} p={6}>
   <HStack h={'97%'} w={'95%'}
    background='#FAFAFA 0% 0% no-repeat padding-box'
    border=' 2px solid #BFBFBF42'
    borderRadius='24px'
   >

   </HStack>
</Box>
    </VStack>


</Container>
  )
}

export default ViewPlans