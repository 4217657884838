import React from 'react'
import { Button, Image, Container, Box, Text, HStack, VStack, FormControl, FormLabel, Input } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
const AddQuote = (props) => {
  return (
    <Container bg={'rgba(56, 56, 64, 0.6)'}
    position={'absolute'}
    h={'100vh'} maxW={'100vw'}
    top={0}
    left={0}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
>
    <Box h={'54vh'} w={'40vw'}
        justifyContent={'space-between'}
        bg={'rgba(255, 255, 255, 1)'}
        borderRadius={'27px'}
        p={3}
    >
                        <HStack h={'20%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={3}
                >
                    <Text w={'95%'}
                        fontSize={'16px'}
                        fontWeight={600}>Add New Quotes</Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.setOpenQuote(!props.OpenQuote)}
                        _hover={{ cursor: "pointer" }}
                        w={'5%'}
                    />
                </HStack>
                <VStack h={'75%'} w={'98%'} justifyContent={'space-between'} alignItems={'center'} >
                    <FormControl h={'75%'} w={'100%'} p={5}>
                        <FormLabel h={'15%'} color={'rgba(23, 23, 23, 0.6)'}
                         fontSize={'12px'}
                         marginTop={1}
                         >Enter your Quotes</FormLabel>
                        <Input h={'30%'} type='text'
                        border={'none'}
                        borderBottom={'1px'} borderBottomColor={'grey'}
                        borderRadius={'none'}
                        _focusVisible={'none'}
                        fontSize={'13px'}
                        />
                        <FormLabel h={'15%'} color={'rgba(23, 23, 23, 0.6)'}
                         fontSize={'12px'} mt={1}>Enter Publishing Date</FormLabel>
                        <Input type='text' h={'20%'}
                        
                        border={'none'}
                        borderBottom={'1px'} borderBottomColor={'grey'}
                        borderRadius={'none'}
                        _focusVisible={'none'}
                        fontSize={'13px'}
                        />
                    </FormControl>
                    <Button h={'25%'} w={'60%'}
                      background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
                      boxShadow='5px 15px 15px #19BD2630'
                      borderRadius='18px'
                      marginTop={'6px'}
                      color={'#FFFFFF'}
                      textAlign='center' 
                     fontSize={'14px'}
                    >Add Quote</Button>

                </VStack>
                
    </Box>
    </Container>
  )
}

export default AddQuote