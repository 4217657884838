import img1 from '../assets/totalrest.png';
export const restaurants_data = [
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:5,
        plan_details:'Bo partner'
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:3,
        plan_details:'BO Verified'
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:4,
        plan_details:'Bo partner'
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:4,
        plan_details:'--'
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:3,
        plan_details:'Bo partner'
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
        mobileno: 9123456789,
        food_type:'chinese',
        rating:2,
        plan_details:'BO Verified'
    }
]