import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import { Container, Text, VStack, HStack, Input, Image, Button } from '@chakra-ui/react'
import btn_filter from '../../assets/btn-filter.png'
import { distributer_data } from '../distributer_data';
import watch from '../../assets/action/watch.png';
import edit from '../../assets/action/edit.png';
import delete_icon from '../../assets/action/delete.png';
import { useState } from 'react';
import AddDistributer from './AddDistributer';
import SendPartnerPlanDistributer from './SendPartnerPlanDistributer';
const Distributers = () => {
  const [addDist,SetaddDist]= useState(false)

  const [view_sendpartner_distributer, setview_sendpartner_distributer] =useState(false)

  return (
    <>
    <Container m={4} minHeight={'90vh'} maxW={'1030px'}
      justifyContent={'space-between'}
      background='#FAFAFA 0% 0% no-repeat padding-box'
      border=' 2px solid #BFBFBF42'
      borderRadius='24px'
    >
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
        <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
          <VStack h={'100%'} w={'40%'} justifyContent={'space-between'} alignItems={'flex-start'}>
            <Text
              h={'35%'}
              w={'90%'}
              fontSize='21px'
              fontWeight={600}
              color='#171717'
              marginTop={4}
              paddingLeft={4}
            >
              All Distributers List
            </Text>
            <HStack marginLeft='18px' m={2} justifyContent={'flex-end'} gap={3} h={'60%'} w={'95%'}
            >
              <Input type='text' placeholder='search here' marginLeft={'12px'}
                background='#FFFFFF 0% 0% no-repeat padding-box'
                border='1px solid #BFBFBF66'
                borderRadius='15px'
                fontSize={'14px'}
              />
              <Image src={btn_filter} alt='' />
            </HStack>
          </VStack>
          <HStack h={'100%'} width={'60%'} justifyContent={'flex-end'} gap={'18px'} paddingRight={'7px'}>
            <Button h={'52px'} w={'200px'} border={'1px solid #19BD26'} 
            borderRadius='14px' bg={'none'} 
            color={'#19BD26'} fontSize={'14px'}
            onClick={()=> setview_sendpartner_distributer(!view_sendpartner_distributer)}
            > Send Partner Plans</Button>
            <Button h={'52px'} w={'200px'} borderRadius='14px' bg={'#19BD26'}
             color={'#FFFFFF'} fontSize={'14px'}
             onClick={()=> SetaddDist(!addDist)}
             >+ &nbsp;Add New Distributer</Button>
          </HStack>
        </HStack>
        <VStack height={'75%'} w={'100%'} justifyContent={'center'} >
          <TableContainer h={'95%'} w={'100%'} p={0}>
            <Table variant="simple" h={'100%'} w='100%' p={0}>

              <Thead height={'15%'} w={'95%'}>
                <Tr>
                  <Th textTransform="none" w={'7%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}  >Id</Th>
                  <Th textTransform="none" w={'18%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Distributer</Th>
                  <Th textTransform="none" w={'13%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Mobile No</Th>
                  <Th textTransform="none" w={'10%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Cuisiness</Th>
                  <Th textTransform="none" w={'23%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Location</Th>
                  <Th textTransform="none" w={'14%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Plan Details</Th>
                  <Th textTransform="none" w={'14%'} fontWeight={'600'} fontSize={'14px'} color={'#171717'}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height={'80%'} w={'100%'}>
                {
                  distributer_data.map((item, index) => {
                    return (
                      <Tr minH={'90px'} w='95%' m={1} justifyContent={'space-between'} key={index} borderTop={'2px solid #BFBFBF42'}>
                        <Td w={'7%'} fontSize={'12px'} color={'#171717'}>{item.Id}</Td>
                        <Td fontSize={'12px'} w={'18%'} color={'#171717'}> {item.distributer}</Td>
                        <Td w={'10%'} fontSize={'12px'} color={'#171717'}>{item.mobileno}</Td>
                        <Td w={'23%'} fontSize={'12px'} color={'#171717'}>{item.food_type}</Td>
                        <Td w={'14%'} fontSize={'12px'} color={'#171717'}>{item.location}</Td>
                        <Td w={'14%'} fontSize={'12px'} fontWeight={600} color={item.plan_details === "BO Verified" ? '#19BD26' : '#D6BD00'}>{item.plan_details}</Td>
                        <Td p={0} w='14%' h={'100%'} fontSize={'md'} color={'#171717'}>
                          <HStack h={'100%'} w={'100%'}>
                            <Image src={watch} alt='' w={'33%'} />
                            <Image src={edit} alt='' w={'33%'} />
                            <Image src={delete_icon} alt='' w={'33%'} />
                          </HStack>
                        </Td>
                      </Tr>
                    )
                  })
                }
              </Tbody>
            </Table>
          </TableContainer>
          <HStack h={'5%'} w={'100%'} margin={5} gap={2} justifyContent={'flex-start'}>
            <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Previous</Button>
            <Button bg='none' fontSize={'13px'} fontWeight={600}>1</Button>
            <Button bg='none' fontSize={'13px'} fontWeight={600}>2</Button>
            <Button bg='none' fontSize={'13px'} fontWeight={600}>3</Button>
            <Button bg='none' fontSize={'13px'} fontWeight={600}>. . .</Button>
            <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Next</Button>
          </HStack>
        </VStack>

      </VStack>
    </Container>
    {
      addDist ? <AddDistributer addDist={addDist} SetaddDist={SetaddDist} /> : ''
    }
    {
      view_sendpartner_distributer ? <SendPartnerPlanDistributer view_sendpartner_distributer={view_sendpartner_distributer}
       setview_sendpartner_distributer={setview_sendpartner_distributer} /> :''
    }
    </>
  )
}

export default Distributers