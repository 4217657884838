import React from 'react'
import { Button, Image, Container, Box, Text, HStack, VStack, Input } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
import { sendpartner_data } from './sendpartner_data'
import { useState } from 'react'
import ViewPlans from './ViewPlans'
const SendPartnerPlans = (props) => {
    const [viewplan,setviewplan] = useState(false)
    return (
        <Container bg={'rgba(56, 56, 64, 0.6)'}
            position={'absolute'}
            h={'100vh'} maxW={'100vw'}
            top={0}
            left={0}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box h={'94vh'} w={'34vw'}
                justifyContent={'space-between'}
                bg={'rgba(255, 255, 255, 1)'}
                borderRadius={'27px'}
                p={3}
            >
                <HStack h={'8%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={2}
                >
                    <Text w={'95%'}
                        fontSize={'15px'}
                        fontWeight={600}>Send Partner Plans 
                        <Text as={'span'} color='#51B1FA'
                        onClick={()=> setviewplan(!viewplan)}
                        fontSize='11px'
                        _hover={{ cursor: "pointer" }}
                        ml={1}>(view plans)</Text> </Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.setOpenSendPartner(!props.OpenSendPartner)}
                        _hover={{ cursor: "pointer" }}
                        w={'6%'}
                    />
                </HStack>
                <VStack h={'92%'} w={'98%'}>
                    <Input type='text' placeholder='search'
                        paddingLeft={'10px'} fontSize={'11px'}
                        borderRadius={'10px'} _focusVisible={'none'} m={2}
                        h={'8%'}
                    />

                    <HStack h={'10%'} w={'100%'} justifyContent={'space-between'}>
                        <Text w={'70%'} fontSize={'12px'}
                            color={'#19BD26'} fontWeight={600}
                        >Select the restaurents</Text>
                        <HStack w={'25%'} gap={3} justifyContent={'flex-end'}>
                            <Text fontSize={'11px'} fontWeight={600}>Select All</Text>
                            <Input w={3} h={6} type='checkbox' />
                        </HStack>
                    </HStack>
                    <VStack h={'60%'} gap={2} w={'100%'}>
                        {
                            sendpartner_data.map((item, index) => {
                                return <HStack h={'60px'} w={'100%'} key={index} p={2} justifyContent={'space-between'}>
                                    <Image w={'12%'} src={item.img_url} alt='' />
                                    <VStack w={'75%'} alignItems={'flex-start'} ml={2}>
                                        <Text h={'50%'} fontSize={'11px'}>{item.fname}</Text>
                                        <Text h={'50%'} fontSize={'11px'}>{item.address}</Text>
                                    </VStack>
                                    <Input type='checkbox' w={3} h={6} />
                                </HStack>
                            })
                        }
                    </VStack>
                    <Button h={'10%'}
                        background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
                        boxShadow='5px 15px 15px #19BD2630'
                        borderRadius='17px'
                        width='70%'
                        color={'#FFFFFF'}
                        textAlign='center'
                        fontSize={'16px'} m={4}>Send Plans</Button>
                </VStack>
            </Box>
            {
                viewplan ? <ViewPlans viewplan={viewplan} setviewplan={setviewplan} /> : ''
            }
        </Container>
    )
}

export default SendPartnerPlans