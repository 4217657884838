import adminpng from '../assets/totalusers.png' 
import postpic from '../assets/postpic.webp'
export const postsdata = [
    {
       img_url:adminpng,
       admin_name:'Caroline Tan',
       time:'2hrs ago',
       content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
       post_url:postpic,
       likes:25 ,
       comments:7
    },
    {
        img_url:adminpng,
        admin_name:'Caroline Tan',
        time:'2hrs ago',
        content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
        post_url:postpic,
        likes:25 ,
        comments:7
     },
     {
        img_url:adminpng,
        admin_name:'Caroline Tan',
        time:'2hrs ago',
        content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
        post_url:postpic,
        likes:25 ,
        comments:7
     },
     {
        img_url:adminpng,
        admin_name:'Caroline Tan',
        time:'2hrs ago',
        content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
        post_url:postpic,
        likes:25 ,
        comments:7
     },
     {
        img_url:adminpng,
        admin_name:'Caroline Tan',
        time:'2hrs ago',
        content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
        post_url:postpic,
        likes:25 ,
        comments:7
     },
     {
        img_url:adminpng,
        admin_name:'Caroline Tan',
        time:'2hrs ago',
        content:'Lorem Ipsum is simply dummy text of the printing and type setting industry.',
        post_url:postpic,
        likes:25 ,
        comments:7
     },
]