export const help_data = [
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
    {
        question:'Question goes here',
        related:'payment FAQ',
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
    },
]