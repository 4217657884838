import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/auth/Login';
import AuthProvider from './providers/AuthProvider';
import Dashboard from './components/dashboard/Dashboard';
import PrivateRoutes from './components/utils/PrivateRoutes';
import Forget from './components/auth/Forget';
import AllRest from './components/resutaurant/AllRest';
import ManageMeals from './components/Meals/ManageMeals';
import DailyQuotes from './components/Quotes/DailyQuotes';
import AllPosts from './components/Posts/AllPosts';
import SubPlan from './components/subscription/SubPlan';
import Help from './components/help/Help';
import Distributers from './components/distributers/Distributers'
import UserMangement from './components/userMangement/UserMangement'
function App() {
  return (
    <AuthProvider key={'authprovider'}>
      <Router>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/all_restaurants' element={<AllRest/>}/>
            <Route path='/manage-meals' element={<ManageMeals/>}/>
            <Route path='/daily-quotes' element={<DailyQuotes/>}/>
            <Route path='/my-posts' element={<AllPosts/>} />
            <Route path='/subs-plans' element={<SubPlan/>}/>
            <Route path='/help' element={<Help/>}/>
            <Route path='/all-distributers' element={<Distributers/>}/>
            <Route path='/user-management' element={<UserMangement/>}/>
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/forget' element={<Forget />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
