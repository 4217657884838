import meals_png from '../assets/addedmeal.png'
export const meals_data = [
    {
        img_url:meals_png,
        Meal_Title:'Biryani',
        Meal_Type:'lunch',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Non-Veg',
        Total_Meal_Taken:'1.5k',
        Overall_Rating:4,
    },
    {
        img_url:meals_png,
        Meal_Title:'Dosa',
        Meal_Type:'Break Fast',
        Serving:'1 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Veg',
        Total_Meal_Taken:'5k',
        Overall_Rating:5,
    },
    {
        img_url:meals_png,
        Meal_Title:'Fried Rice',
        Meal_Type:'lunch',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Veg',
        Total_Meal_Taken:'1.5k',
        Overall_Rating:4,
    },
    {
        img_url:meals_png,
        Meal_Title:'Biryani',
        Meal_Type:'lunch',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Non-Veg',
        Total_Meal_Taken:'7.5k',
        Overall_Rating:5,
    },
    {
        img_url:meals_png,
        Meal_Title:'Idli',
        Meal_Type:'BreakFast',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Veg',
        Total_Meal_Taken:'1.5k',
        Overall_Rating:4,
    },
    {
        img_url:meals_png,
        Meal_Title:'Biryani',
        Meal_Type:'lunch',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Non-Veg',
        Total_Meal_Taken:'1.5k',
        Overall_Rating:4,
    },
    {
        img_url:meals_png,
        Meal_Title:'Roti',
        Meal_Type:'Dinner',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Veg',
        Total_Meal_Taken:'1.7k',
        Overall_Rating:3,
    },
    {
        img_url:meals_png,
        Meal_Title:'Paneer',
        Meal_Type:'lunch',
        Serving:'2 people',
        Description:'Lorem ipsom is simple dummy text....',
        Meal_Category:'Veg',
        Total_Meal_Taken:'7k',
        Overall_Rating:2,
    },
]