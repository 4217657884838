import { Box, HStack, Text, VStack, Image } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/Logo/logo3.png';
import { sidebarlinksdata } from './sidebarlinksdata';
import logout from '../../assets/logout.png';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
export default function SidebarLinks({ onClick }) {
    return (
        <Box bg='#FFFFFF' w='100%'
            overflowY='auto'
            h='100vh'
            maxW='245px'
            justifyContent={'center'}
            alignItems={'center'}>
            <VStack
                onClick={onClick}
                w='100%'
                overflowY='auto'
                h='100vh'
                maxW='245px'
                justifyContent={'space-between'}
                alignItems={'center'}
                background='transparent linear-gradient(180deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
                _hover={{
                    bg: 'transparent linear-gradient( 180deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box',
                    shadow: 'sm',
                }
                }

                borderTopRightRadius="40px"
                borderBottomRightRadius="40px"
            >
                <Box pt={4} w='100%' h={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box h={'20%'} w='100%' justifyContent={'center'} >
                        <Link to={'/#'} >
                            <HStack alignItems='center' justifyContent='center' w='100%' mb='4'>
                                <Image src={logo} w={'43%'} />
                            </HStack>
                        </Link>
                    </Box>
                    <Box h={'65%'} w={'100%'}>
                        {
                            sidebarlinksdata.map((item, index) => {
                                return (<Link key={index} to={item.path}>
                                    <HStack _hover={{ color: '#FFFFFF' }} color='rgba(255, 255, 255, 0.7)' p='3' justifyContent={'flex-start'} paddingLeft={'25px'} w={'100%'} >
                                        <Image src={item.icon_url} alt='' h={'100%'} w={'9%'} marginRight={'7px'}></Image>
                                        <Text fontWeight='500' fontSize={'md'} h={'100%'} w={'80%'}>{item.title}</Text>
                                        <Text justifyContent={'flex-end'} h={'100%'} w={'10%'}><MdOutlineKeyboardArrowRight /></Text>
                                    </HStack>
                                </Link>)
                            })
                        }
                    </Box>
                    <Box h={'10%'} w={'100%'}>
                        <Link to={'/'} >
                            <HStack _hover={{ color: '#FFFFFF' }} color='rgba(255, 255, 255, 0.9)' p='3' w={'100%'} justifyContent={'flex-start'} alignItems={'center'} paddingLeft={'25px'} >
                                <Image src={logout} alt='' h={'100%'} w={'10%'} marginRight={'7px'}></Image>
                                <Text fontWeight='500' fontSize={'14px'} h={'100%'} w={'80%'}>Logout</Text>
                            </HStack>
                        </Link>
                    </Box>
                </Box>
            </VStack >
        </Box>
    )
}
