import React from 'react'
import { Container, Text, VStack, HStack, Input, Image, Button, Box } from '@chakra-ui/react'
import { useState } from 'react';
import watch from '../../assets/action/watch.png';
import edit from '../../assets/action/edit.png';
import delete_icon from '../../assets/action/delete.png';
import { usermanage_data } from '../usermanage_data';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
const UserMangement = () => {
  const [sub_userlist, setsub_userlist] = useState(false)
  return (
    <>
      <Box m={3} p={1.5}
        h={'60px'}
        w={'340px'}
        alignSelf={'flex-start'}
        borderRadius={'17px'}
        marginLeft={'30px'}
        backgroundColor='#F2F2F2'
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Button h={'100%'} w={'49%'} borderRadius={'12px'}
          fontSize={'15px'} justifyContent={'center'} alignItems={'center'}
          onClick={() => setsub_userlist(false)}
          bg={sub_userlist ? '#F2F2F2' : '#19BD26'}
          color={sub_userlist ? 'rgba(23, 23, 23, 0.35)' : '#FFFFFF'}
          _hover={sub_userlist ? '' : {
            bg: 'transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box',
            shadow: 'sm'
          }}
        >All Users</Button>
        <Button h={'100%'} w={'50%'} borderRadius={'12px'}
          fontSize={'14px'} justifyContent={'center'} alignItems={'center'}
          onClick={() => setsub_userlist(true)}
          bg={sub_userlist ? '#19BD26' : '#F2F2F2'}
          color={sub_userlist ? '#FFFFFF' : 'rgba(23, 23, 23, 0.35)'}
          _hover={sub_userlist ? {
            bg: 'transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box',
            shadow: 'sm'
          } : ''}
        >Subscribed Users</Button>
      </Box>
      <Container m={4} minHeight={'90vh'} maxW={'1040px'}
        justifyContent={'space-between'}
        background='#FAFAFA 0% 0% no-repeat padding-box'
        border=' 2px solid #BFBFBF42'
        borderRadius='24px'
      >
        <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
          <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={7} marginTop={5}>
            <Text
              h={'100%'}
              w={'30%'}
              fontSize='21px'
              fontWeight={600}
              color='#171717'
              marginTop={4}
              paddingLeft={4}
              justifyContent={'flex-start'}
            >
              All Users List
            </Text>
            <Input placeholder='search here' h={'40px'} w={'27%'}
              borderRadius={'14px'} type='text'
              borderColor={'#BFBFBF66'} bg={'#FFFFFF'} fontSize={'13px'} />
          </HStack>
          <VStack height={'75%'} w={'100%'} justifyContent={'center'} >
            <TableContainer h={'95%'} w={'100%'} p={0}>
              <Table variant="simple" p={0}>

                <Thead height={'15%'} w={'95%'} p={0}>
                  <Tr>
                    <Th textTransform={'none'} w={'13%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>User Name</Th>
                    <Th textTransform={'none'} w={'10%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Email</Th>
                    <Th textTransform={'none'} w={'10%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Mobile No</Th>
                    <Th textTransform={'none'} w={'10%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Status</Th>
                    {sub_userlist ? (<>
                      <Th textTransform={'none'} w={'14%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Date of Purchase</Th>
                      <Th textTransform={'none'} w={'18%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Subscription Expiry</Th>
                    </>)
                      : (<>
                        <Th textTransform={'none'} w={'18%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Subscrition Status</Th>
                        <Th textTransform={'none'} w={'14%'} fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Joined Date</Th>
                      </>)}

                    <Th textTransform={'none'} w={'12%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Meals Taken</Th>
                    <Th textTransform={'none'} w={'12%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Recipe Created</Th>
                    <Th textTransform={'none'} w='10%' fontWeight={'600'} fontSize={'13px'} color={'#171717'}>Action</Th>
                  </Tr>
                </Thead>

                <Tbody height={'80%'} w={'100%'}>
                  {sub_userlist ? usermanage_data.filter(item => item.subscribed === 'Subscribed')
                    .map((item, index) => {
                      return (
                        <Tr minH={'84px'} w='95%' m={1} justifyContent={'space-between'} key={index} borderTop={'2px solid #BFBFBF42'}>

                          <Td w={'13%'} justifyContent={'space-evenly'} p={0}>
                            <HStack w={'100%'} >
                              <Image w={'20%'}
                                fontWeight={'600'} fontSize={'15px'} color={'#171717'}
                                borderRadius={'50%'} src={item.img_url} alt='' />
                              <Text w={'70%'} fontSize={'10px'}>{item.user_name}</Text>
                            </HStack>
                          </Td>

                          <Td fontSize={'10px'} w={'10%'} color={'#171717'}>{item.email}</Td>
                          <Td w={'10%'} fontSize={'11px'} color={'#171717'}>{item.mobileno}</Td>
                          <Td w={'10%'} fontSize={'11px'} color={'#171717'}>{item.status}</Td>
                          <Td w={'14%'} fontSize={'11px'} color={'#171717'}>{item.date_of_purchase}</Td>
                          <Td w={'18%'} fontSize={'11px'} color={'#171717'}>{item.subs_expiry}</Td>
                          <Td w={'12%'} fontSize={'11px'} color={'#171717'}>{item.meals_taken}</Td>
                          <Td w={'12%'} fontSize={'11px'} color={'#171717'}>{item.recipes_created}</Td>
                          <Td w='10%' fontSize={'11px'} color={'#171717'} p={0}>
                            <HStack w={'100%'}>
                              <Image src={watch} alt='' w={'33%'} />
                              <Image src={edit} alt='' w={'33%'} />
                              <Image src={delete_icon} alt='' w={'33%'} />
                            </HStack>

                          </Td>
                        </Tr>
                      )
                    }) : usermanage_data.map((item, index) => {
                      return (
                        <Tr minH={'84px'} w='95%' m={1} justifyContent={'space-between'} key={index} borderTop={'2px solid #BFBFBF42'}>

                          <Td w={'13%'} p={0}>
                            <HStack >
                              <Image w={'20%'}
                                fontWeight={'600'} fontSize={'15px'} color={'#171717'}
                                borderRadius={'50%'} src={item.img_url} alt='' />
                              <Text w={'70%'} fontSize={'10px'}>{item.user_name}</Text>
                            </HStack>
                          </Td>

                          <Td fontSize={'10px'} w={'10%'} color={'#171717'}>{item.email}</Td>
                          <Td w={'10%'} fontSize={'11px'} color={'#171717'}>{item.mobileno}</Td>
                          <Td w={'10%'} fontSize={'11px'} color={'#171717'}>{item.status}</Td>
                          <Td w={'18%'} fontSize={'11px'} color={'#171717'}>{item.subscribed}</Td>
                          <Td w={'14%'} fontSize={'11px'} color={'#171717'}>{item.joined_date}</Td>
                          <Td w={'12%'} fontSize={'11px'} color={'#171717'}>{item.meals_taken}</Td>
                          <Td w={'12%'} fontSize={'11px'} color={'#171717'}>{item.recipes_created}</Td>
                          <Td w='10%' fontSize={'11px'} color={'#171717'} p={0}>
                            <HStack>
                              <Image src={watch} alt='' />
                              <Image src={edit} alt='' />
                              <Image src={delete_icon} alt='' />
                            </HStack>

                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </TableContainer>
            <HStack h={'5%'} w={'100%'} margin={5} gap={2} justifyContent={'flex-start'}>
              <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Previous</Button>
              <Button bg='none' fontSize={'13px'} fontWeight={600}>1</Button>
              <Button bg='none' fontSize={'13px'} fontWeight={600}>2</Button>
              <Button bg='none' fontSize={'13px'} fontWeight={600}>3</Button>
              <Button bg='none' fontSize={'13px'} fontWeight={600}>. . .</Button>
              <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Next</Button>
            </HStack>
          </VStack>
        </VStack>
      </Container>
    </>
  )
}

export default UserMangement