import totalusers from './totalusers.png'
import newusers from './newusers.png'
import totalrest from './totalrest.png'
import addedmeal from './addedmeal.png'
export  const dashboard_assets = [
 {
    img_url :totalusers,
    numberof: '40.2k',
    title:"Total Users",
    subtitle:"Till Date"
 },
 {
    img_url :newusers,
    numberof: '18.6k',
    title:"Total New Users",
    subtitle:"Till Date"
 },
 {
    img_url :totalrest,
    numberof: '40.2k',
    title:"Total Restaurents",
    subtitle:"This Month"
 },
 {
    img_url :addedmeal,
    numberof: '40.2k',
    title:"Added Meal",
    subtitle:"This Month"
 },
]