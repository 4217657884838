import { Button, Image, Container, Box, Text, HStack, VStack, FormControl, FormLabel, Input } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
import React from 'react'
import send_icon from '../../assets/send-icon.png'
const AddDistributer = (props) => {
    return (
        <Container bg={'rgba(56, 56, 64, 0.6)'}
            position={'absolute'}
            h={'100vh'} maxW={'100vw'}
            top={0}
            left={0}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box h={'94vh'} w={'38vw'}
                justifyContent={'space-between'}
                bg={'rgba(255, 255, 255, 1)'}
                borderRadius={'27px'}
                p={3}
            >
                <HStack h={'8%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={2} m={1}
                >
                    <Text w={'95%'}
                        fontSize={'15px'}
                        fontWeight={600}>Add New Distributer</Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.SetaddDist(!props.addDist)}
                        _hover={{ cursor: "pointer" }}
                        w={'6%'}
                    />
                </HStack>
                <VStack h={'92%'} w={'96%'} justifyContent={'space-between'} alignItems={'center'}
                    p={3} 
                >
                    <FormControl h={'90%'} w={'100%'} justifyContent={'space-between'}>

                        <FormLabel h={'4%'} fontSize={'11px'}>Distributer title</FormLabel>
                        <Input h={'4%'} marginBottom={3} fontSize={'11px'} type='text' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />

                        <FormLabel h={'4%'} fontSize={'11px'}>Address</FormLabel>
                        <Input h={'4%'} marginBottom={3} fontSize={'11px'} type='text' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />
                        <HStack h={'8%'} marginBottom={3} justifyContent={'space-between'}>

                            <VStack w={'45%'} h={'100%'}>
                                <FormLabel h={'80%'} alignSelf={'flex-start'} fontSize={'11px'}>Open Time</FormLabel>
                                <Input h={'20%'} fontSize={'11px'} type='text' border={'none'}
                                    borderBottom={'2px'} borderBottomColor={'grey'}
                                    borderRadius={'none'}
                                    _focusVisible={'none'} />
                            </VStack>

                            <VStack w={'45%'} h={'100%'}>
                                <FormLabel h={'80%'} alignSelf={'flex-start'} fontSize={'11px'}>Close Time</FormLabel>
                                <Input h={'20%'} type='text' border={'none'}
                                    borderBottom={'2px'} borderBottomColor={'grey'}
                                    borderRadius={'none'}
                                    _focusVisible={'none'}
                                    fontSize={'11px'} />
                            </VStack>

                        </HStack>
                        <FormLabel h={'4%'} fontSize={'11px'}>Phone Number</FormLabel>
                        <Input h={'4%'} marginBottom={3} fontSize={'11px'} type='number' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />

                        <FormLabel h={'4%'} fontSize={'11px'}>Website</FormLabel>
                        <Input h={'4%'} marginBottom={3} fontSize={'11px'} type='text' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />

                        <FormLabel h={'4%'} fontSize={'11px'}>Cusiness</FormLabel>
                        <Input h={'4%'} marginBottom={3} fontSize={'11px'} type='text' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />

                        <FormLabel h={'4%'} fontSize={'11px'}>Description</FormLabel>
                        <Input h={'4%'} marginBottom={3} type='text' border={'none'}
                            borderBottom={'2px'} borderBottomColor={'grey'}
                            borderRadius={'none'}
                            _focusVisible={'none'} />
                        <HStack h={'4%'} margin={3} w={'30%'}>
                            <Image src={send_icon} alt=''
                                w={'14%'} />
                            <Text w={'80%'} color={'#19BD26'} fontSize={'11px'}>Send Partner Plan</Text>
                        </HStack>
                    </FormControl>
                    <Button
                        background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
                        boxShadow='5px 15px 15px #19BD2630'
                        borderRadius='17px'
                        width='70%'
                        color={'#FFFFFF'}
                        textAlign='center'
                        h={'10%'} fontSize={'16px'}>Add Distributer</Button>
                </VStack>

            </Box>

        </Container>
    )
}

export default AddDistributer