import {
    Box,
    HStack,
    useDisclosure,
    Flex,
    Text,
    useBreakpointValue,
    Menu,
    MenuButton,
    MenuList,
    VStack,
    MenuItem,
    Container,
    Card,
    Input,
    Image,
    Button,
} from '@chakra-ui/react'
import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from './SideBar';
import bellicon from '../../assets/bellicon.png';
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAuth } from '../../providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import Notification from '../notification/Notification';

export default function Navbar() {
    let navigate = useNavigate();

    const [isnotify, setisnotify] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const sideBarVariant = useBreakpointValue({ base: 'drawer', md: 'sidebar' });

    const { logout } = useAuth();

    const handleLogout = () => {
        console.log('logout');
        logout();
        navigate('/')
    };

    const location = useLocation();

    let heading = 'Default Header'; // Default heading

    switch (location.pathname) {
        case '/':
            heading = 'Dashboard';
            break;
        case '/all_restaurants':
            heading = 'All Restaurants';
            break;
        case '/manage-meals':
            heading = 'Manage Meals';
            break;
        case '/daily-quotes':
            heading = 'Daily Quotes';
            break;
        case '/user-management':
            heading = 'User Mangement';
            break;
        case '/all-distributers':
            heading = 'All Distributers';
            break;
        case '/my-posts':
            heading = 'My Posts';
            break;
        case '/subs-plans':
            heading = 'Subscription Plans';
            break;
        case '/help':
            heading = 'Help and Support Center';
            break;
        default:
            heading = 'Dashboard';
    }

    return (
        <>
            <Box px={4} w='100%' >
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'} marginTop={2}>
                    {sideBarVariant === 'drawer' && (
                        <SideBar />
                    )}
                    <Flex w='100%'>
                        <HStack justifyContent='space-between' w='100%'>
                            <Text className='custom' fontSize='23px' w={'25%'} fontWeight={600}>
                                {heading}
                            </Text>
                            <HStack w={'37%'} justifyContent={'flex-start'}>
                                <Input type='text' placeholder='search here' w={'80%'}
                                    background='#FFFFFF 0% 0% no-repeat padding-box'
                                    paddingLeft={'30px'}
                                    fontSize='15px'
                                    border='1px solid #BFBFBF'
                                    borderRadius='15px' _focusVisible={'none'}></Input>
                            </HStack>
                            <HStack spacing={6} w={'40%'} justifyContent={'flex-end'}>
                                <Menu>
                                    <MenuButton onClick={() => setisnotify(!isnotify)}>
                                        <Card bg='secondary' p={1} >
                                            <Image src={bellicon} alt='bellicon'     
                                                h={'45px'} w={'45px'} />
                                            <Container>
                                                <Box
                                                    bg='#E22937'
                                                    w='6px'
                                                    h='6px'
                                                    display='flex'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    rounded='50%'
                                                    position={'absolute'}
                                                    top='1'
                                                >
                                                </Box>
                                            </Container>
                                        </Card>

                                    </MenuButton>
                                </Menu>

                                <Menu>
                                    <MenuButton>
                                        <HStack spacing='2'>
                                            <img src='./user.png' width={40} />
                                            <VStack spacing={0}>
                                                <Text fontSize={'sm'} fontWeight={600}>Prince</Text>
                                                <Text fontSize={'sm'}>Admin</Text>
                                            </VStack>
                                            <MdKeyboardArrowDown size='20' opacity={'0.8'} />
                                        </HStack>
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem minH='40px'>
                                            <Link to={'/#'}>
                                                <Text pl={1}>Settings</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px'>
                                            <Link to={'/#'}>
                                                <Text pl={1}>Admin Management</Text>
                                            </Link>
                                        </MenuItem>

                                        <MenuItem minH='40px' onClick={handleLogout}>
                                            <Text pl={1}>Log Out</Text>
                                        </MenuItem>

                                    </MenuList>
                                </Menu>
                            </HStack>
                        </HStack>
                    </Flex>
                </Flex>

            </Box >
            <ToastContainer />
            {
                isnotify ? <Notification isnotify={isnotify} setisnotify={setisnotify} /> : ''
            }
        </>
    )
}
