import React from 'react'
import { Container, Text, VStack, HStack, Input, Image, Button, Box, Card } from '@chakra-ui/react'
import { useState } from 'react';
import { help_data } from '../help_data';
import edit from '../../assets/action/edit.png';
import delete_icon from '../../assets/action/delete.png';

const Help = () => {

  const [SupportChat, setSupportChat] = useState(false)
  return (
    <>
      <Box m={3} p={1.5}
        h={'60px'}
        w={'340px'}
        alignSelf={'flex-start'}
        borderRadius={'17px'}
        marginLeft={'30px'}
        backgroundColor='#F2F2F2'
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Button h={'100%'} w={'49%'} borderRadius={'12px'}
          fontSize={'15px'} justifyContent={'center'} alignItems={'center'}
          onClick={() => setSupportChat(false)}
          bg={SupportChat ? '#F2F2F2' : '#19BD26'}
          color={SupportChat ? 'rgba(23, 23, 23, 0.35)' : '#FFFFFF'}
          _hover={SupportChat ? '' : {
            bg: 'transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%)',
            shadow: 'sm'
          }}
        >All FAQ's</Button>
        <Button h={'100%'} w={'50%'} borderRadius={'12px'}
          fontSize={'14px'} justifyContent={'center'} alignItems={'center'}
          onClick={() => setSupportChat(true)}
          bg={SupportChat ? '#19BD26' : '#F2F2F2'}
          color={SupportChat ? '#FFFFFF' : 'rgba(23, 23, 23, 0.35)'}
          _hover={SupportChat ? {
            bg: 'transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%)',
            shadow: 'sm'
          } : ''}
        >Support Chat</Button>
      </Box>
      {
        SupportChat ? ''
          :
          <Container m={4} minHeight={'90vh'} maxW={'1040px'}
            justifyContent={'space-between'}
            background='#FAFAFA'
            border=' 2px solid #BFBFBF42'
            borderRadius='24px'
          >
            <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
              <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
                <Text
                  h={'100%'}
                  w={'30%'}
                  fontSize='21px'
                  fontWeight={600}
                  color='#171717'
                  marginTop={4}
                  paddingLeft={4}
                  justifyContent={'flex-start'}
                >
                  All FAQ's
                </Text>
                <Button h={'52px'} w={'200px'} borderRadius='14px' bg={'#19BD26'} color={'#FFFFFF'} fontSize={'14px'}>+ &nbsp;Add New FAQ</Button>
              </HStack>
              <VStack height={'76%'} w={'100%'} gap={4}>
                {
                  help_data.map((item, index) => {
                 return <Card h={'120px'} w={'95%'} key={index} m={2} borderRadius={'18px'}
                 boxShadow={'4px 8px 32px #00000029'} 
                 bg={'#FFFFFF 0% 0% no-repeat padding-box'} 
                >
                  <HStack h={'100%'} w={'100%'}>
                    <VStack  h={'100%'} w={'85%'} justifyContent={'flex-start'} p={5} gap={2}>
                      <HStack h={'40%'} w={'100%'} justifyContent={'flex-start'} gap={3}>
                        <Text fontSize={'22px'} fontWeight={600}>{item.question}</Text>
                        <Text fontSize={'13px'} color={'#19BD26'}>{item.related}</Text>
                        </HStack>
                        <Text h={'60%'} fontSize={'14px'} color={'rgba(23, 23, 23, 0.6)'}>{item.content}</Text>
                    </VStack>
                    <Image src={edit} alt='' h={'40%'}/>
                    <Image src={delete_icon} alt='' h={'40%'}/>
                  </HStack>

                 </Card>
                  })
                }
              </VStack>
            </VStack>
          </Container>
      }

    </>
  )
}

export default Help