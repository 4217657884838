import React, { useState } from 'react'
import { postsdata } from '../postsdata'
import postmenu from '../../assets/postadmin.png'
import chat from '../../assets/chat.png'
import like from '../../assets/heart.png'
import { Container, Box, Text, VStack, HStack, Image, Button, Card } from '@chakra-ui/react'
import AddPost from './AddPost'
const AllPosts = () => {
  const [Addpost, setAddpost] = useState(false)
  return (
    <Container m={4} minHeight={'90vh'} maxW={'1030px'}
      justifyContent={'space-between'}
      background='#FAFAFA 0% 0% no-repeat padding-box'
      border=' 2px solid #BFBFBF42'
      borderRadius='24px'
    >
      <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
        <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
          <Text
            h={'100%'}
            w={'30%'}
            fontSize='21px'
            fontWeight={600}
            color='#171717'
            marginTop={4}
            paddingLeft={4}
            justifyContent={'flex-start'}
          >
            All Posts List
          </Text>
          <Button h={'52px'} w={'200px'} borderRadius='14px' 
          bg={'#19BD26'} color={'#FFFFFF'} fontSize={'14px'}
          onClick={()=> setAddpost(!Addpost)}
          >+ &nbsp;Add New Post</Button>
        </HStack>
        <Box height={'75%'} w={'100%'} gap={5} flexWrap={'wrap'} display={'flex'} alignItems={'center'} justifyContent={'space-evenly'}>
          {
            postsdata.map((item, index) => {
              return <Card key={index} height={'300px'} width={'300px'} 
              bgColor={'none'} p={2} justifyContent={'space-between'} 
              boxShadow={'none'} marginBottom={'10px'}>
                <HStack height={'15%'} width={'100%'} justifyContent={'space-between'}>
                  <Image src={item.img_url} alt='' borderRadius={'50%'} h={'80%'}/>
                  <VStack w={'70%'} h={'90%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Text fontSize={'12px'} fontWeight={600}>{item.admin_name}</Text>
                    <Text fontSize={'10px'} color={'#707070'}>{item.time}</Text>
                  </VStack>
                  <Image src={postmenu} alt='' w={'6%'}/>
                </HStack>
                <VStack h={'70%'} w={'100%'} justifyContent={'space-between'}>
                  <Text fontSize={'11px'} h={'20%'}>{item.content}</Text>
                  <Image src={item.post_url} h={'90%'} alt='' borderRadius={'10px'} />
                </VStack>
                <HStack h={'5%'} w={'100%'} alignItems={'flex-start'}>
                  <Image src={like} alt='' h={'70%'}/>
                  <Text fontSize={'10px'} fontWeight={600}>{item.likes}</Text>
                  <Image src={chat} alt='' h={'70%'}/>
                  <Text fontSize={'10px'} fontWeight={600}>{item.comments}</Text>
                </HStack>
              </Card>
            })
          }
        </Box>
      </VStack> 
{
  Addpost ? <AddPost Addpost={Addpost}  setAddpost = {setAddpost}/>:''
}

    </Container>
  )
}

export default AllPosts