import React from 'react'
import { Container, Text, VStack, HStack, Input, Image, Button } from '@chakra-ui/react'
import { meals_data } from '../meals_data';
import { FaStar } from "react-icons/fa6";
import watch from '../../assets/action/watch.png';
import edit from '../../assets/action/edit.png';
import delete_icon from '../../assets/action/delete.png';
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
const ManageMeals = () => {
    return (
        <Container m={4} minHeight={'90vh'} maxW={'1030px'}
            justifyContent={'space-between'}
            background='#FAFAFA 0% 0% no-repeat padding-box'
            border=' 2px solid #BFBFBF42'
            borderRadius='24px'
        >
            <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
                <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
                    <VStack h={'100%'} w={'40%'} justifyContent={'space-between'} alignItems={'flex-start'}>
                        <Text
                            h={'35%'}
                            w={'90%'}
                            fontSize='21px'
                            fontWeight={600}
                            color='#171717'
                            marginTop={4}
                            paddingLeft={4}
                        >
                            All Meal Items
                        </Text>
                        <HStack marginLeft='18px' m={2} justifyContent={'flex-start'} h={'60%'} w={'95%'}
                        >
                            <Input type='text' placeholder='search here' marginLeft={'12px'}
                                background='#FFFFFF 0% 0% no-repeat padding-box'
                                border='1px solid #BFBFBF66'
                                borderRadius='15px'
                                fontSize={'14px'}
                                width={'70%'}
                            />
                        </HStack>
                    </VStack>
                    <HStack h={'100%'} width={'60%'} justifyContent={'flex-end'} paddingRight={'7px'}>
                        <Button h={'52px'} w={'200px'} borderRadius='14px' bg={'#19BD26'} color={'#FFFFFF'} fontSize={'14px'}>+ &nbsp;Add New Meal</Button>
                    </HStack>
                </HStack>
                <VStack height={'75%'} w={'100%'} justifyContent={'center'} >

                    <TableContainer h={'95%'} w={'100%'} p={0}>
                        <Table variant="simple" p={0}>

                            <Thead height={'15%'} w={'95%'} p={0}>
                                <Tr>
                                    <Th textTransform="none" w={'5%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}  >Image</Th>
                                    <Th textTransform="none" w={'9%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Meal Title</Th>
                                    <Th textTransform="none" w={'9%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Meal Type</Th>
                                    <Th textTransform="none" w={'10%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Serving</Th>
                                    <Th textTransform="none" w={'18%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Description</Th>
                                    <Th textTransform="none" w={'11%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Meal Category</Th>
                                    <Th textTransform="none" w={'12%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Total Meal Taken</Th>
                                    <Th textTransform="none" w={'10%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Overall Rating</Th>
                                    <Th textTransform="none" w={'14%'} fontWeight={'600'} fontSize={'12px'} color={'#171717'}>Action</Th>
                                </Tr>
                            </Thead>

                            <Tbody height={'80%'} w={'95%'} p={0}>
                                {
                                    meals_data.map((item, index) => {
                                        return (
                                            <Tr minH={'90px'} w='95%' m={1} justifyContent={'space-between'} key={index} borderTop={'2px solid #BFBFBF42'}>
                                                <Td w={'5%'} p={2}>
                                                    <Image src={item.img_url} alt='' w={'80%'} />
                                                </Td>
                                                <Td fontSize={'12px'} w={'9%'} color={'#171717'}>{item.Meal_Title}</Td>
                                                <Td fontSize={'12px'} w={'9%'} color={'#171717'}>{item.Meal_Type}</Td>
                                                <Td w={'10%'} fontSize={'12px'} color={'#171717'}>{item.Serving}</Td>
                                                <Td w={'18%'} fontSize={'12px'} color={'#171717'}>{item.Description}</Td>
                                                <Td w={'11%'} fontSize={'12px'} color={'#171717'}>{item.Meal_Category}</Td>
                                                <Td w={'12%'} fontSize={'12px'} color={'#171717'}>{item.Total_Meal_Taken}</Td>
                                                <Td w={'10%'} p={0} fontSize={'12px'} color={'#171717'}>
                                                    <HStack>
                                                        {
                                                            item.Overall_Rating === 1 ? <FaStar color='yellow' />
                                                                : item.Overall_Rating === 2 ? <HStack>
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                </HStack> : item.Overall_Rating === 3 ? <HStack>
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                </HStack> : item.Overall_Rating === 4 ? <HStack>
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                </HStack> : item.Overall_Rating === 5 ? <HStack>
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                    <FaStar color='#F7B900' />
                                                                </HStack> : ""

                                                        }
                                                        <Text>{item.Overall_Rating}</Text></HStack></Td>
                                                <Td w={'14%'} p={0}>
                                                    <HStack w='100%' fontSize={'12px'} color={'#171717'}>
                                                        <Image src={watch} alt='' />
                                                        <Image src={edit} alt='' />
                                                        <Image src={delete_icon} alt='' />
                                                    </HStack>
                                                </Td>


                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <HStack h={'5%'} w={'100%'} margin={5} gap={2} justifyContent={'flex-start'}>
                        <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Previous</Button>
                        <Button bg='none' fontSize={'13px'} fontWeight={600}>1</Button>
                        <Button bg='none' fontSize={'13px'} fontWeight={600}>2</Button>
                        <Button bg='none' fontSize={'13px'} fontWeight={600}>3</Button>
                        <Button bg='none' fontSize={'13px'} fontWeight={600}>. . .</Button>
                        <Button bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Next</Button>
                    </HStack>
                </VStack>
            </VStack>
        </Container>
    )
}

export default ManageMeals