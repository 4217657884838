import React from 'react'
import { quotes_data } from '../quotes_data'
import clock_icon from '../../assets/clock-icon.png'
import tick_button from '../../assets/tick-button.png'
import edit from '../../assets/action/edit.png';
import { useState } from 'react';
import delete_icon from '../../assets/action/delete.png';
import { Container, Text, VStack, HStack, Image, Button, Card } from '@chakra-ui/react'
import AddQuote from './AddQuote';
import EditQuote from './EditQuote';
const DailyQuotes = () => {
    const [updateQuote, setupdateQuote] = useState(false)
    const [OpenQuote, setOpenQuote] = useState(false)
    return (
        <Container m={4} minHeight={'90vh'} maxW={'1030px'}
            justifyContent={'space-between'}
            background='#FAFAFA 0% 0% no-repeat padding-box'
            border=' 2px solid #BFBFBF42'
            borderRadius='24px'
        >
            <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
                <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
                    <Text
                        h={'100%'}
                        w={'30%'}
                        fontSize='21px'
                        fontWeight={600}
                        color='#171717'
                        marginTop={4}
                        paddingLeft={4}
                        justifyContent={'flex-start'}
                    >
                        All Quotes
                    </Text>
                    <Button h={'52px'} w={'200px'} borderRadius='14px' bg={'#19BD26'}
                     color={'#FFFFFF'} fontSize={'14px'}
                     onClick={()=> setOpenQuote(!OpenQuote)}
                     >+ &nbsp;Add New Quote</Button>
                </HStack>
                <VStack height={'72%'} w={'100%'} gap={4}>
                    {
                        quotes_data.map((item, index) => {
                            return <Card h={'120px'} w={'95%'} key={index} m={2} borderRadius={'18px'}
                            boxShadow={'4px 8px 32px #00000029'} 
                            bg={'#FFFFFF 0% 0% no-repeat padding-box'} 
                           >
                                <HStack h={'100%'} w={'100%'}>
                                    <VStack h={'100%'} w={'85%'} justifyContent={'flex-start'} p={5} gap={3}>
                                        <Text h={'92%'} fontSize={'14px'} fontWeight={600}>{item.content}</Text>
                                        <HStack  w={'100%'} justifyContent={'flex-start'}  gap={3}>
                                            <Image src={clock_icon} alt='' />
                                            <Text fontSize={'12px'} color={'#848A94'}>{item.data}</Text>
                                            <Text fontSize={'12px'} color={'#848A94'}>{item.time}</Text>
                                        </HStack>

                                    </VStack>
                                    <HStack  h={'100%'} w={'15%'} justifyContent={'center'} p={4}>
                                        <Image src={tick_button} alt='' />
                                        <Image src={edit} alt='' _hover={{cursor:'pointer'}} onClick={()=> setupdateQuote(!updateQuote)} />
                                        <Image src={delete_icon} alt='' />
                                    </HStack>
                                </HStack>
                            </Card>
                        })
                    }
                </VStack>
                <HStack h={'5%'} w={'100%'}   margin={2} gap={2} justifyContent={'flex-start'} >
                 <Button  bg='none' fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Previous</Button>
                 <Button bg='none' fontSize={'13px'} fontWeight={600}>1</Button>
                 <Button bg='none' fontSize={'13px'} fontWeight={600}>2</Button>
                 <Button bg='none' fontSize={'13px'} fontWeight={600}>3</Button>
                 <Button bg='none' fontSize={'13px'} fontWeight={600}>. . .</Button>
                 <Button bg='none'  fontSize={'13px'} fontWeight={600} color={'#19BD26'}>Next</Button>
                </HStack>
            </VStack>
            {
            OpenQuote ? <AddQuote OpenQuote = { OpenQuote} setOpenQuote ={setOpenQuote} /> :''
        } 
        {
            updateQuote ? <EditQuote updateQuote ={updateQuote} setupdateQuote={setupdateQuote} />: ''
        }
        </Container>
    )
}

export default DailyQuotes