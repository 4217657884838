export const subdata = [
    {
        title: 'Subscription Title',
        content: 'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about.',
        time: 'yearly plan',
        cost: '$80'
    },
    {
        title: 'Subscription Title',
        content: 'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about.',
        time: 'yearly plan',
        cost: '$80'
    },
    {
        title: 'Subscription Title',
        content: 'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about.',
        time: 'yearly plan',
        cost: '$80'
    },

]