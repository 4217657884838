import React from 'react';
import subs_start from '../../assets/subs-start.png';
import coin from '../../assets/coin-dollar.png';
import time_check from '../../assets/time-check.png';
import edit from '../../assets/action/edit.png';
import delete_icon from '../../assets/action/delete.png';
import { subdata } from '../subdata';
import { Container, Text, VStack, HStack, Image, Button, Card, Box } from '@chakra-ui/react';
const SubPlan = () => {
  return (
    <>
      <Box
        h={'16vh'} w={'270px'}
        backgroundColor='rgba(25, 189, 38, 0.16)'
        alignSelf={'flex-start'}
        borderRadius={'30px'}
        marginLeft={'30px'}
      >
        <HStack p={3} h={'100%'} w={'100%'} justifyContent={'space-evenly'}>
          <Image src={subs_start} alt='' h={'80%'} />
          <VStack p={5} alignItems={'flex-start'}>
            <Text fontSize={'22px'} fontWeight={'600'} alignItems={'flex-start'}>12.2 K</Text>
            <Text fontSize={'12px'} >Total User Subscribed</Text>
          </VStack>
        </HStack>
      </Box>
      
      <Container
      m={4} minHeight={'90vh'} maxW={'1050px'}
        justifyContent={'space-between'}
        background='#FAFAFA 0% 0% no-repeat padding-box'
        border=' 2px solid #BFBFBF42'
        borderRadius='24px'

      >
        <VStack h={'100%'} w={'100%'} justifyContent={'space-between'} >
          <HStack height={'20%'} w={'100%'} justifyContent={'space-between'} marginBottom={5} marginTop={5}>
            <Text
              h={'100%'}
              w={'30%'}
              fontSize='21px'
              fontWeight={600}
              color='#171717'
              marginTop={4}
              paddingLeft={4}
              justifyContent={'flex-start'}
            >
              Manage Subscription Plan
            </Text>
            <Button h={'52px'} w={'200px'} borderRadius='14px' bg={'#19BD26'} color={'#FFFFFF'} fontSize={'14px'}>+ &nbsp;Add New Plan</Button>
          </HStack>
          <VStack height={'78%'} w={'100%'} gap={4}>
            {
              subdata.map((item, index) => {
                return (<Card key={index}
                  h={'160px'} w={'95%'} m={2} borderRadius={'18px'}
                  boxShadow={'4px 8px 32px #00000029'}
                  bg={'#FFFFFF 0% 0% no-repeat padding-box'} p={2}>
                  <HStack h={'100%'} w={'100%'} p={3}>
                    <VStack w={'85%'} alignItems={'flex-start'}>
                      <Text h={'40%'} fontSize={'22px'} fontWeight={600}>{item.title}</Text>
                      <HStack h={'20%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <Image src={coin} alt='' />
                        <Text fontSize={'12px'} fontWeight={600}>{item.cost}</Text>
                        <Image src={time_check} alt='' />
                        <Text fontSize={'12px'} fontWeight={600}>{item.time}</Text>
                      </HStack>
                      <Text h={'30%'} fontSize={'13px'}>{item.content}</Text>
                    </VStack>
                    <HStack w={'15%'} justifyContent={'center'} alignItems={'center'}>
                      <Image src={edit} alt='' />
                      <Image src={delete_icon} alt='' />
                    </HStack>
                  </HStack>
                </Card>)
              })
            }
          </VStack>
        </VStack>
      </Container>
    </>
  )
}

export default SubPlan