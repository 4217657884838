
export const sendpartner_distributerdata = [
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    {
        id:12345,
        fname:'Name here' 
    },
    
]