export const quotes_data = [
    {
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
        data:'12/3/24',
        time:'2:00 PM'

    },
    {
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
        data:'12/3/24',
        time:'2:00 PM'

    },
    {
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
        data:'12/3/24',
        time:'2:00 PM'

    },
    {
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
        data:'12/3/24',
        time:'2:00 PM'

    },
    {
        content:'Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about ',
        data:'12/3/24',
        time:'2:00 PM'

    },
]