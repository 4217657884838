import user from '../assets/Logo/Logo2.png'
export const usermanage_data = [
    {
        img_url:user,
        user_name: 'user name 1',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'--',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'--',
        subs_expiry:'--' 
    },
    {
        img_url:user,
        user_name: 'user name 2',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'Subscribed',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'25/8/24',
        subs_expiry:'25/8/25'
    },
    {
        img_url:user,
        user_name: 'user name 3',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'Subscribed',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'25/8/24',
        subs_expiry:'25/8/25'
    },
    {
        img_url:user,
        user_name: 'user name 4',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'--',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'--',
        subs_expiry:'=='
    },
    {
        img_url:user,
        user_name: 'user name 5',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'Subscribed',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'25/8/24',
        subs_expiry:'25/8/25'
    },
    {
        img_url:user,
        user_name: 'user name 6',
        email:'abc@gmail.com',
          mobileno: 9123456789,
        status:'active',
        subscribed:'--',
        joined_date:'21/4/24',
        meals_taken:420,
        recipes_created:50,
        date_of_purchase:'--',
        subs_expiry:'--'
    },
]