import img1 from '../../assets/totalrest.png';
export const sendpartner_data = [
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
    },
    {
        img_url: img1,
        fname:'restaurant name',
        address : '3-24 srikakulam , andhrapradesh',
    },
]