import React from 'react'
import {Image, Container, Box, Text, HStack, VStack,Card } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
import { notifications_data } from './notification_data'
import notify_icon from '../../assets/notify-icon.png'
const Notification = (props) => {
  return (
    <Container bg={'rgba(56, 56, 64, 0.6)'}
    position={'absolute'}
    h={'100vh'} maxW={'100vw'}
    top={0}
    right={0}
    display={'flex'}
    justifyContent={'flex-end'}
    alignItems={'flex-start'}
    zIndex={1}
    paddingTop={'80px'}
    paddingRight={'150px'}
>
<Box h={'52vh'} w={'22vw'}
                justifyContent={'space-between'}
                bg={'rgba(255, 255, 255, 1)'}
                borderRadius={'27px'}
                p={3}
                alignItems={'center'}
                top={'100px'}
            >
<HStack h={'17%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={2}
                >
                    <Text w={'85%'}
                        fontSize={'13px'}
                        fontWeight={600}>Notification</Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.setisnotify(!props.isnotify)}
                        _hover={{ cursor: "pointer" }}
                        w={'10%'}
                    />
                </HStack>
<VStack h={'80%'} w={'100%'} p={1} >
{
    notifications_data.map((item,index)=>{
        return <Card h={'24%'} w={'100%'} key={index}>
            <HStack h={'100%'} w={'100%'} justifyContent={'space-between'}>
            <Image src={notify_icon} alt='' w={'18%'}/>
            <Text w={'62%'} fontSize={'10px'}>{item.content}</Text>
            <Text w={'20%'} fontSize={'10px'}>{item.time}</Text>
            </HStack>

        </Card>
    })
}
</VStack>
            </Box>
</Container>
  )
}

export default Notification