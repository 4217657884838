export const notifications_data = [
    {
        content:'Lorem Ipsum is simply dummy text that ...',
        time:'3:00 PM'
    },
    {
        content:'Lorem Ipsum is simply dummy text that...',
        time:'3:00 PM'
    },
    {
        content:'Lorem Ipsum is simply dummy text that...',
        time:'3:00 PM'
    },
    {
        content:'Lorem Ipsum is simply dummy text that...',
        time:'3:00 PM'
    },
]