import React from 'react'
import { Button, Image, Container, Box, Text, HStack, VStack, FormControl, FormLabel, Input } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
const AddPost = (props) => {
    return (
        <Container bg={'rgba(56, 56, 64, 0.6)'}
            position={'absolute'}
            h={'100vh'} maxW={'100vw'}
            top={0}
            left={0}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box h={'62vh'} w={'38vw'}
                justifyContent={'space-between'}
                bg={'rgba(255, 255, 255, 1)'}
                borderRadius={'27px'}
                p={3}
                alignItems={'center'}
            >
                <HStack h={'17%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={2}
                >
                    <Text w={'95%'}
                        fontSize={'16px'}
                        fontWeight={600}>Create New post</Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.setAddpost(!props.Addpost)}
                        _hover={{ cursor: "pointer" }}
                        w={'6%'}
                    />
                </HStack>
                <VStack h={'75%'} w={'98%'} p={2}>
                 <FormControl h={'90%'} w={'100%'} m={1} p={1}>
                 <FormLabel fontSize={'12px'} fontWeight={400}>Upload Your image</FormLabel>
                <Input type='file' border={'none'} fontSize={'11px'} marginBottom={3} />
                <FormLabel fontSize={'12px'}
                fontWeight={400}
                m={2}>
                    enter the discription
                </FormLabel>
                <Input type='text' 
                border={'none'}
                borderBottom={'1px'} borderBottomColor={'grey'}
                borderRadius={'none'}
                _focusVisible={'none'}
                fontSize={'13px'}
                h={'35%'}

                />
                
                 </FormControl>
                 <Button 
               background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
            boxShadow='5px 15px 15px #19BD2630'
            borderRadius='18px'
            width='70%'
            marginTop={'6px'}
            color={'#FFFFFF'}
            textAlign='center' 
            h={'70px'} fontSize={'16px'}>Create</Button>
                </VStack>
            </Box>
        </Container>
    )
}

export default AddPost