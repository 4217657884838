import usermanage from '../../assets/usermanagement.png';
import dashboard from '../../assets/dashboard.png';
import manage_meal from '../../assets/manage_meal.png';
import alldist from '../../assets/all_distributers.png';
import allres from '../../assets/store.png';
import dailyQ from '../../assets/daily_quotes.png';
import myposts from '../../assets/myposts.png';
import subplan from '../../assets/subscription.png';
import chat from '../../assets/chat.png';
export const sidebarlinksdata =[
    {
        icon_url:dashboard,
        title:'Dashboard',
        path:'/'
        
    },
    {
        icon_url:usermanage,
        title:'User Mangement',
        path:'/user-management'
    },
    {
        icon_url:allres,
        title:'All Restaurants',
        path:'/all_restaurants'
    },
    {
        icon_url:alldist,
        title:'All Distributers',
        path:'/all-distributers'
    },
    {
        icon_url:manage_meal,
        title:'Manage Meal',
        path:'/manage-meals'
    },
    {
        icon_url:dailyQ,
        title:'Daily Quotes',
        path:'/daily-quotes'
    },
    {
        icon_url:myposts,
        title:'My Posts',
        path:'/my-posts'
    },
    {
        icon_url:subplan,
        title:'Subscription Plans',
        path:'/subs-plans'
    },
    {
        icon_url:chat,
        title:'Help and Support Center',
        path:'/help'
    }
]