import { Container, Card, HStack, Text, VStack,Image } from "@chakra-ui/react"
import React from 'react'

import { dashboard_assets } from "../../assets/dashboardassests"
export default function Dashboard() {
    return (
        
        <Container height={'100vh'} maxW={'100vw'} justifyContent={"space-between"}>
       <HStack height={'30vh'} width={'100%'} m={2}>
      {
        dashboard_assets.map((item,index)=>{
        return <Card  key={index} height={'95%'} w={'24%'} p={5} 
        backgroundColor= 'rgba(25, 189, 38, 0.16)'
        borderRadius={'30px'} >
            <VStack h={'100%'} w={'100%'} >
            <Image src={item.img_url} alt="" ></Image>
            <Text fontSize='xl' fontWeight={600} >{item.numberof} </Text>
            <Text fontSize={'14px'} Color='rgba(23, 23, 23, 0.6)'>{item.title}</Text>
            <Text fontSize={'12px'} Color='rgba(23, 23, 23, 0.6)'>{item.subtitle}</Text>
            </VStack>
        </Card>
        })
      }
       </HStack>
        </Container>
    )
}
