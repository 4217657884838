import React from 'react'
import { Box, VStack, HStack, Image } from '@chakra-ui/react'
import { Button, FormControl, FormLabel, Input, Text, useBreakpointValue } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import BgPic from '../../assets/Background.png'
import Logo from '../../assets/Logo/Logo.png';
export default function Login() {
  const width = useBreakpointValue({ base: "100%", sm: "380px", md: "405px", lg: "440px" });
  return (
    <Box
      height={'100vh'}
      maxW={'100vw'}
      display='flex' justifyContent='center' alignItems='center'
      top='0px'
      left='0px'
      background='#FFFFFF 0% 0% no-repeat padding-box'
      opacity='1'
      overflow={'hidden'}
    >
      <Box
        top='-439.0457458496094px'
        left='-924.9573974609375px'
        width='1839px'
        position='absolute'
        zIndex='1'
      >
        <Image src={`${BgPic}`} alt='bgpic' height={'1520px'} width={'1839px'}  ></Image>
      </Box>
      <Image src={`${Logo}`} alt='logo'
        top='68px'
        left='120px'
        width='149px'
        height='173px'
        opacity='1'
        zIndex={'3'}
        position='absolute'
      ></Image>
      <Box
        height={['66vh', 'md']}
        maxW={width}
        w={'100%'}
        display='flex' justifyContent='center' alignItems='center'
        padding='12px'
        zIndex={'3'}
        top='277px'
        left='721px'
        background='#FFFFFF 0% 0% no-repeat padding-box'
        boxShadow='0px 5px 15px #0000001F'
        borderRadius='27px'
        opacity='1'
      >

        <VStack display='flex' align='center' height='100%' width='100%' justifyContent='space-between'>
          <VStack height='25%' width='85%' alignItems={'flex-start'}>
            <Text fontSize={['20px', '22px', '26px', '28px']} fontWeight='700' height='100%' width='90%' justifyContent='flex-start' alignItems={'center'} display='flex'>Welcome ! Lets Login To your Account</Text>
          </VStack>
          <FormControl height='40%' width='85%' display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexDirection={'column'} marginTop={'4px'}>
            <FormLabel fontSize={'12px'} height='14%' justifyContent={'center'} alignItems={'center'} paddingLeft={'6px'} width='100%'>Your Email</FormLabel>
            <Input backgroundColor={'none'}
              height='35%' width='100%'
              placeholder='Enter Your Email'
              paddingLeft={'8px'}
              marginBottom={'6px'} marginTop={'3px'}
              border={'none'}
              borderBottom={'2px'} borderBottomColor={'grey'}
              borderRadius={'none'}
              _focusVisible={'none'}
            />
            <FormLabel fontSize={'12px'} height='14%' width='100%' paddingLeft={'6px'} >Your Password</FormLabel>
            <Input backgroundColor={'none'}
              height='35%' width='100%'
              type='password'
              placeholder='Enter Your Password'
              paddingLeft={'8px'}
              marginBottom={'3px'} marginTop={'3px'}
              border={'none'}
              borderBottom={'2px'} borderBottomColor={'grey'}
              borderRadius={'none'}
              _focusVisible={'none'}
            />
          </FormControl>
          <HStack height='15%' width='85%' justify='flex-end'>
            <NavLink to='/forget' height='100%' width='100%'>
              <Button variant='link' fontSize={'13px'} height='100%' width='100%' color={'limegreen'}>Forget Password?</Button>
            </NavLink>
          </HStack>
          <Button
            background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
            boxShadow='5px 15px 15px #19BD2630'
            borderRadius='20px'
            width='402px'
            height='67px'
            textAlign='center'
            font='normal normal 600 15px/18px Poppins'
            letterSpacing='0px'
            color='#FFFFFF'
            textTransform='uppercase'
            opacity='1'
          >SIGN IN</Button>
        </VStack>
      </Box>
    </Box>
  )
}