export const distributer_data = [
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'--'
    },
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'BO Verified'
    },
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'BO Partner'
    },
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'--'
    },
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'BO Partner'
    },
    {
        Id: 12345,
        distributer:'name here',
          mobileno: 9123456789,
           food_type:'chinese',
       location : '3-24 srikakulam , andhrapradesh',
        plan_details:'BO Verified'
    },
]