import React from 'react'
import { Button, Image, Container, Box, Text, HStack, VStack, FormControl, FormLabel, Input } from '@chakra-ui/react'
import close_btn from '../../assets/close-btn.png'
const EditQuote = (props) => {
  return (
    <Container bg={'rgba(56, 56, 64, 0.6)'}
    position={'absolute'}
    h={'100vh'} maxW={'100vw'}
    top={0}
    left={0}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
>
    <Box h={'54vh'} w={'40vw'}
        justifyContent={'space-between'}
        bg={'rgba(255, 255, 255, 1)'}
        borderRadius={'27px'}
        p={3}
    >
                        <HStack h={'20%'} w={'98%'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    p={3}
                >
                    <Text w={'95%'}
                        fontSize={'16px'}
                        fontWeight={600}>Add New Quotes</Text>
                    <Image src={close_btn} alt=''
                        onClick={() => props.setupdateQuote(!props.updateQuote)}
                        _hover={{ cursor: "pointer" }}
                        w={'5%'}
                    />
                </HStack>
                <VStack h={'75%'} w={'98%'} justifyContent={'space-between'} alignItems={'center'} >
                    <FormControl h={'75%'} w={'100%'} p={5}>
                        <Input h={'60%'} type='text'
                        placeholder='Lorem ipsom is simple dummy text that can all done for all that has been working to know ipsom is simple dummy text that can all done for all that has been working to know about '
                        border={'none'}
                        borderBottom={'1px'} borderBottomColor={'grey'}
                        borderRadius={'none'}
                        _focusVisible={'none'}
                        fontSize={'13px'}
                        w={'100%'}
                        />
                        <Input type='text' h={'38%'}
                         placeholder='17/8/24 9:00 PM'
                        border={'none'}
                        borderBottom={'1px'} borderBottomColor={'grey'}
                        borderRadius={'none'}
                        _focusVisible={'none'}
                        fontSize={'13px'}
                        />
                    </FormControl>
                    <Button h={'25%'} w={'60%'}
                      background='transparent linear-gradient(262deg, #1EDD2D 0%, #0D8C17 100%) 0% 0% no-repeat padding-box'
                      boxShadow='5px 15px 15px #19BD2630'
                      borderRadius='18px'
                      marginTop={'6px'}
                      color={'#FFFFFF'}
                      textAlign='center' 
                     fontSize={'14px'}
                    >Update</Button>

                </VStack>
                
    </Box>
    </Container>
  )
}

export default EditQuote